import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './TextField.module.css'

const TextField = ({ className, style, type = 'text', children, invalid, ...props }) => (
  <input
    type={type}
    className={cx(styles.root, { [styles.error]: invalid }, className)}
    style={style}
    autoComplete="false"
    spellCheck="false"
    {...props}
  />
)

TextField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  invalid: PropTypes.bool,
}

export default TextField
