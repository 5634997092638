import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Title from '@components/Title'

import styles from './Stepper.module.css'

class Stepper extends React.PureComponent {
  render() {
    const { className, style, children, title } = this.props
    return (
      <div className={cx(styles.root, className)} style={style}>
        {title && (
          <Title className={styles.stepperTitle} level={3}>
            {title}
          </Title>
        )}
        {children}
      </div>
    )
  }
}

Stepper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
}

export default Stepper
