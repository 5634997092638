import isEmail from 'is-email'
import { DateUtils } from 'react-day-picker'

const twentyFive = Array(25).fill('')

const timeOptions = [
  '12:00 pm',
  '12:30 pm',
  '1:00 pm',
  '1:30 pm',
  '2:00 pm',
  '2:30 pm',
  '3:00 pm',
  '3:30 pm',
  '4:00 pm',
  '4:30 pm',
  '5:00 pm',
  '5:30 pm',
  '6:00 pm',
  '6:30 pm',
  '7:00 pm',
  '7:30 pm',
  '8:00 pm',
  '8:30 pm',
  '9:00 pm',
  '9:30 pm',
  '10:00 pm',
  '10:30 pm',
  '11:00 pm',
]

export default {
  date: {
    error: 'Please choose or type a valid date',
    placeholder: 'Choose a date',
    label: 'On which day?',
    format: 'dddd, MMMM Do YYYY',
    validate: date => DateUtils.isDate(date),
  },
  time: {
    error: "Please select the cruise's sail time",
    placeholder: 'Select a time',
    label: 'At what time?',
    options: timeOptions,
    validate: time => !!time.length,
  },
  duration: {
    error: 'Please select the cruise duration',
    placeholder: 'Select a duration',
    label: 'For how long?',
    options: ['1h', '1h 30m', '2h', '2h 30m', '3h', '3h 30m', '4h', '4h 30m', '5h', '5h 30m', '6h'],
    validate: duration => !!duration.length,
  },
  guests: {
    validate: guests => !!guests.length,
    error: 'Please select the number of guests',
    placeholder: 'Select total guests',
    label: 'For how many?',
    options: twentyFive.map((e, i) => `${++i} guest${i > 1 ? 's' : ''}`).reverse(),
  },
  title: {
    validate: title => !!title.length,
    error: 'Please select a title',
    placeholder: 'Select a title',
    options: ['Mr.', 'Mrs.'],
  },
  name: {
    error: 'Please type a valid name',
    placeholder: 'Type your full name',
    label: 'What is your name?',
    validate: name => !!name.length,
  },
  email: {
    error: 'Please type a valid email address',
    placeholder: 'Type your email address',
    label: 'Your email address?',
    validate: email => isEmail(email),
  },
  phone: {
    error: 'Please type a valid phone number',
    placeholder: 'Type your phone number',
    label: 'Your phone number?',
    validate: phone => phone.length > 6,
  },
  catering: {
    label: 'Catering services',
    info: 'If you require our Catering services, we will get in touch with you',
    options: ['No thanks', 'Yes please'],
  },
  parking: {
    label: 'Parking Spots',
    info: 'Our Parking spots can suit up to 4 cars and are completely free of charge',
    options: ['None', 'For 1 car', 'For 2 cars', 'For 3 cars', 'For 4 cars'],
  },
  comments: {
    label: 'Comments',
    info: 'Any questions or special requests?',
  },
}
