import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Lead from '@components/Lead'

import styles from './Pricing.module.css'

class Pricing extends React.PureComponent {
  render() {
    const { className, style, children } = this.props
    return (
      <React.Fragment>
        <div className={cx(styles.root, className)} style={style}>
          <Lead className={styles.title}>Our rates</Lead>
          <p>The following rates are for the rental of the whole yacht, regardless of the number of people.</p>
          <ul className={styles.ratesList}>
            <li>
              <div>One hour or two hours</div> <div>1650 EGP per hour</div>
            </li>
            <li>
              <div>Three or four hours</div> <div>1600 EGP per hour</div>
            </li>
            <li>
              <div>Five hours or more</div> <div>1550 EGP per hour</div>
            </li>
          </ul>
          <span className={styles.vat}>
            All our rates include <abbr title="Value-added tax">VAT</abbr>.
          </span>
          <hr />
          <p>Please note that the payment is only accepted in cash and is expected at the end of the cruise.</p>
          {children}
        </div>
      </React.Fragment>
    )
  }
}

Pricing.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Pricing
