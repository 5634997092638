import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { formatDate } from 'react-day-picker/moment'
import {
  FaCar,
  FaComments,
  FaRegEnvelope,
  FaParking,
  FaRegCalendarAlt,
  FaRegClock,
  FaHourglassHalf,
  FaRegAddressBook,
  FaUsers,
} from 'react-icons/fa'

import { MdRestaurant, MdPhone } from 'react-icons/md'

import Button from '@components/Button'
import DatePicker from '@components/DatePicker'
import FormError from '@components/FormError'
import FormLabel from '@components/FormLabel'
import Lead from '@components/Lead'
import PaddedBlock from '@components/PaddedBlock'
import Radio from '@components/Radio'
import Select from '@components/Select'
import Stepper from '@components/Stepper'
import TextArea from '@components/TextArea'
import TextField from '@components/TextField'

import i18n from './ReservationForm.i18n.js'
import styles from './ReservationForm.module.css'

class ReservationForm extends React.Component {
  state = {
    date: '',
    time: '',
    duration: '',
    guests: '',
    title: i18n.title.options[0],
    name: '',
    email: '',
    phone: '',
    parking: i18n.parking.options[0],
    catering: i18n.catering.options[0],
    comments: '',
    dateError: false,
    timeError: false,
    durationError: false,
    guestsError: false,
    titleError: false,
    nameError: false,
    emailError: false,
    phoneError: false,
  }

  handleDateChange = date => {
    this.setState({ date, dateError: !i18n.date.validate(date) })
  }

  handleTimeChange = ({ value }) => {
    this.setState({ time: value, timeError: !i18n.time.validate(value) })
  }

  handleDurationChange = ({ value }) => {
    this.setState({ duration: value, durationError: !i18n.duration.validate(value) })
  }

  handleGuestsChange = ({ value }) => {
    this.setState({ guests: value, guestsError: !i18n.guests.validate(value) })
  }

  handleTitleChange = ({ target }) => {
    this.setState({ title: target.value, titleError: !i18n.title.validate(target.value) })
  }

  handleNameChange = ({ target }) => {
    this.setState({ name: target.value, nameError: !i18n.name.validate(target.value) })
  }

  handleEmailChange = ({ target }) => {
    this.setState({ email: target.value, emailError: !i18n.email.validate(target.value) })
  }

  handlePhoneChange = ({ target }) => {
    this.setState({ phone: target.value, phoneError: !i18n.phone.validate(target.value) })
  }

  handleParkingChange = ({ value }) => {
    this.setState({ parking: value })
  }

  handleCateringChange = ({ target }) => {
    this.setState({ catering: target.value })
  }

  handleCommentsChange = ({ target }) => {
    this.setState({ comments: target.value })
  }

  handleFormSubmit = e => {
    const {
      dateError,
      timeError,
      durationError,
      guestsError,
      titleError,
      nameError,
      emailError,
      phoneError,
    } = this.state
    if (dateError || timeError || durationError || guestsError || titleError || nameError || emailError || phoneError) {
      e.preventDefault()
    }
  }

  validateForm = () => {
    this.setState(
      cs => ({
        dateError: !i18n.date.validate(cs.date),
        timeError: !i18n.time.validate(cs.time),
        durationError: !i18n.duration.validate(cs.duration),
        guestsError: !i18n.guests.validate(cs.guests),
        titleError: !i18n.title.validate(cs.title),
        nameError: !i18n.name.validate(cs.name),
        emailError: !i18n.email.validate(cs.email),
        phoneError: !i18n.phone.validate(cs.phone),
      }),
      () => document.querySelector('form').scrollIntoView()
    )
  }

  renderDate() {
    const { state, handleDateChange } = this
    const { label, format, error } = i18n.date
    const { date, dateError } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="date" icon={FaRegCalendarAlt}>
          {label}
        </FormLabel>
        <DatePicker id="date" invalid={dateError} onDayChange={handleDateChange} value={date} />
        <FormError when={dateError}>{error}</FormError>
        <input name="date" type="hidden" value={formatDate(date, format)} />
      </div>
    )
  }

  renderTime() {
    const { state, handleTimeChange } = this
    const { label, options, placeholder, error } = i18n.time
    const { time, timeError } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="time" icon={FaRegClock}>
          {label}
        </FormLabel>
        <Select
          id="time"
          invalid={timeError}
          onChange={handleTimeChange}
          options={options}
          placeholder={placeholder}
          value={time}
        />
        <FormError when={timeError}>{error}</FormError>
        <input name="time" type="hidden" value={time} />
      </div>
    )
  }

  renderDuration() {
    const { state, handleDurationChange } = this
    const { label, options, placeholder, error } = i18n.duration
    const { duration, durationError } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="duration" icon={FaHourglassHalf}>
          {label}
        </FormLabel>
        <Select
          id="duration"
          invalid={durationError}
          onChange={handleDurationChange}
          options={options}
          placeholder={placeholder}
          value={duration}
        />
        <FormError when={durationError}>{error}</FormError>
        <input name="duration" type="hidden" value={duration} />
      </div>
    )
  }

  renderGuests() {
    const { state, handleGuestsChange } = this
    const { label, options, placeholder, error } = i18n.guests
    const { guests, guestsError } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="guests" icon={FaUsers}>
          {label}
        </FormLabel>
        <Select
          id="guests"
          invalid={guestsError}
          onChange={handleGuestsChange}
          options={options}
          placeholder={placeholder}
          value={guests}
        />
        <FormError when={guestsError}>{error}</FormError>
        <input name="guests" type="hidden" value={guests} />
      </div>
    )
  }

  renderName() {
    const { state, handleNameChange, handleTitleChange } = this
    const { name, nameError, title } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="name" icon={FaRegAddressBook}>
          {i18n.name.label}
        </FormLabel>
        <div className={cx(styles.fieldRow, styles.nameRow)}>
          <div className={styles.fieldset}>
            <div className={cx(styles.fieldRow, styles.nameRow)}>
              {i18n.title.options.map((opt, idx) => (
                <div key={idx} className={styles.fieldset}>
                  <Radio checked={title === opt} id={`title-${idx}`} onChange={handleTitleChange} value={opt}>
                    {opt}
                  </Radio>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.fieldset}>
            <TextField
              id="name"
              invalid={nameError}
              onChange={handleNameChange}
              placeholder={i18n.name.placeholder}
              value={name}
            />
            <FormError when={nameError}>{i18n.name.error}</FormError>
          </div>
        </div>
        <input name="name" type="hidden" value={`${title} ${name}`} />
      </div>
    )
  }

  renderPhone() {
    const { state, handlePhoneChange } = this
    const { label, placeholder, error } = i18n.phone
    const { phone, phoneError } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="phone" icon={MdPhone}>
          {label}
        </FormLabel>
        <TextField
          id="phone"
          invalid={phoneError}
          name="phone"
          onChange={handlePhoneChange}
          placeholder={placeholder}
          type="tel"
          value={phone}
        />
        <FormError when={phoneError}>{error}</FormError>
      </div>
    )
  }

  renderEmail() {
    const { state, handleEmailChange } = this
    const { label, placeholder, error } = i18n.email
    const { email, emailError } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="email" icon={FaRegEnvelope}>
          {label}
        </FormLabel>
        <TextField
          id="email"
          invalid={emailError}
          name="email"
          onChange={handleEmailChange}
          placeholder={placeholder}
          type="email"
          value={email}
        />
        <FormError when={emailError}>{error}</FormError>
      </div>
    )
  }

  renderCatering() {
    const { state, handleCateringChange } = this
    const { label, info, options } = i18n.catering
    const { catering } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="catering" info={info} icon={MdRestaurant}>
          {label}
        </FormLabel>
        <div className={cx(styles.fieldRow, styles.cateringRow)}>
          {options.map((opt, idx) => (
            <div key={idx} className={styles.fieldset}>
              <Radio
                checked={catering === opt}
                id={`catering-${idx}`}
                name="catering"
                onChange={handleCateringChange}
                value={opt}
              >
                {opt}
              </Radio>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderParking() {
    const { state, handleParkingChange } = this
    const { label, info, options } = i18n.parking
    const { parking } = state

    const isOpt1On = parking !== options[0]
    const isOpt2On = [options[2], options[3], options[4]].includes(parking)
    const isOpt3On = [options[3], options[4]].includes(parking)
    const isOpt4On = parking === options[4]

    return (
      <div className={styles.fieldset}>
        <FormLabel for="parking" info={info} icon={FaParking}>
          {label}
        </FormLabel>
        <div className={styles.parkingRow}>
          <Select id="parking" onChange={handleParkingChange} options={options} value={parking} />
          <div className={styles.cars}>
            <FaCar
              className={cx(styles.car, { [styles.on]: isOpt1On })}
              onClick={() => handleParkingChange({ value: options[1] })}
            />
            <FaCar
              className={cx(styles.car, { [styles.on]: isOpt2On })}
              onClick={() => handleParkingChange({ value: options[2] })}
            />
            <FaCar
              className={cx(styles.car, { [styles.on]: isOpt3On })}
              onClick={() => handleParkingChange({ value: options[3] })}
            />
            <FaCar
              className={cx(styles.car, { [styles.on]: isOpt4On })}
              onClick={() => handleParkingChange({ value: options[4] })}
            />
          </div>
        </div>
        <input name="parking" type="hidden" value={parking} />
      </div>
    )
  }

  renderComments() {
    const { state, handleCommentsChange } = this
    const { label, info } = i18n.comments
    const { comments } = state

    return (
      <div className={styles.fieldset}>
        <FormLabel for="comments" info={info} icon={FaComments}>
          {label}
        </FormLabel>
        <TextArea id="comments" name="comments" onChange={handleCommentsChange} value={comments} />
      </div>
    )
  }

  render() {
    const { className, ...rest } = this.props
    return (
      <PaddedBlock className={cx(styles.root, className)}>
        <Lead>Reservation Request</Lead>
        <form
          autoComplete="false"
          spellCheck="false"
          className={styles.form}
          {...rest}
          onSubmit={this.handleFormSubmit}
        >
          <Stepper title="Cruise details">
            <div className={styles.fieldRow}>
              {this.renderDate()}
              {this.renderTime()}
            </div>
            <div className={styles.fieldRow}>
              {this.renderDuration()}
              {this.renderGuests()}
            </div>
          </Stepper>
          <Stepper title="Personal details">
            {this.renderName()}
            <div className={styles.fieldRow}>
              {this.renderPhone()}
              {this.renderEmail()}
            </div>
          </Stepper>
          <Stepper title="Anything to add?">
            {this.renderCatering()}
            {this.renderParking()}
            {this.renderComments()}
          </Stepper>
          <Button type="submit" className={styles.submit} onClick={this.validateForm}>
            Submit my reservation request
          </Button>
        </form>
      </PaddedBlock>
    )
  }
}

ReservationForm.propTypes = {
  className: PropTypes.string,
}

export default ReservationForm
