import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './FormError.module.css'

const FormError = ({ when, className, ...rest }) =>
  when ? <div className={cx(styles.root, className)} {...rest} /> : null

FormError.propTypes = {
  when: PropTypes.bool,
  className: PropTypes.string,
}

export default FormError
