import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'

import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import 'react-day-picker/lib/style.css'

import i18n from '@components/ReservationForm/ReservationForm.i18n.js'
import textFieldStyles from '@components/TextField/TextField.module.css'
import styles from './DatePicker.module.css'

const DatePicker = ({ className, invalid, ...rest }) => {
  const modifierClasses = { [textFieldStyles.error]: invalid }
  const thatMoment = moment()
  const disablePast = thatMoment.add(2, 'days').toDate()
  const disableFuture = thatMoment.add(6, 'months').toDate()

  return (
    <div className={cx({ [styles.error]: invalid })}>
      <DayPickerInput
        inputProps={{ className: cx(textFieldStyles.root, modifierClasses, className) }}
        dayPickerProps={{
          fromMonth: disablePast,
          toMonth: disableFuture,
          disabledDays: [
            { before: disablePast, after: disableFuture },
            { from: new Date(2023, 5, 28), to: new Date(2023, 6, 2) },
          ],
        }}
        format={i18n.date.format}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={i18n.date.placeholder}
        {...rest}
      />
    </div>
  )
}

DatePicker.propTypes = {
  className: PropTypes.string,
  invalid: PropTypes.bool,
}

export default DatePicker
