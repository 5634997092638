import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import textFieldStyles from '@components/TextField/TextField.module.css'
import styles from './Radio.module.css'

const Radio = ({ className, id, style, children, ...rest }) => (
  <label style={style} className={cx(styles.root, className)} htmlFor={id}>
    <input id={id} type="radio" {...rest} />
    <span className={textFieldStyles.root}>{children}</span>
  </label>
)

Radio.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Radio
