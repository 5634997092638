import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Lead.module.css'

const Lead = ({ className, spaceTop, ...rest }) => (
  <p className={cx(styles.root, className, { [styles.spaceTop]: spaceTop })} {...rest} />
)

Lead.propTypes = {
  spaceTop: PropTypes.bool,
  className: PropTypes.string,
}

export default Lead
