import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Dropdown from 'react-dropdown'

import 'react-dropdown/style.css'
import textFieldStyles from '@components/TextField/TextField.module.css'
import styles from './Select.module.css'

const Select = ({ invalid, className, ...props }) => (
  <div className={cx({ [styles.error]: invalid }, className)}>
    <Dropdown
      arrowClassName={styles.arrow}
      controlClassName={cx(textFieldStyles.root, styles.control)}
      menuClassName={styles.menu}
      placeholderClassName={styles.placeholder}
      {...props}
    />
  </div>
)

Select.propTypes = {
  invalid: PropTypes.bool,
  className: PropTypes.string,
}

export default Select
