import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './FormLabel.module.css'

const FormLabel = props => {
  const Icon = props.icon
  return (
    <React.Fragment>
      <label className={cx(styles.root, props.className)} htmlFor={props.for}>
        {props.icon && <Icon />}
        {props.children && <span>{props.children}</span>}
      </label>
      {props.info && <div className={styles.info}>{props.info}</div>}
    </React.Fragment>
  )
}

FormLabel.propTypes = {
  for: PropTypes.string,
  icon: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
}

export default FormLabel
