import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import textFieldStyles from '@components/TextField/TextField.module.css'

const TextArea = ({ className, ...rest }) => <textarea className={cx(textFieldStyles.root, className)} {...rest} />

TextArea.propTypes = {
  className: PropTypes.string,
}

export default TextArea
