import Lead from '@components/Lead'
import Link from '@components/Link'
import PaddedBlock from '@components/PaddedBlock'
import Pricing from '@components/Pricing'
import ReservationForm from '@components/ReservationForm'
import SEO from '@components/SEO'
import React from 'react'
import styles from './reservation.module.css'

const ReservationPage = () => (
  <React.Fragment>
    <SEO title="Reservation" />

    <PaddedBlock className={styles.root}>
      <Lead>
        Thank you for considering
        <br />
        <abbr title="Motor Yatch">M/Y</abbr> Christina for your event
      </Lead>
      <p>
        You can either{' '}
        <Link external to="tel:+201222118533">
          call us
        </Link>
        ,{' '}
        <Link external to="mailto:yachtchristina@gmail.com">
          email us
        </Link>
        ,{' '}
        <Link external to="https://wa.me/201222118533">
          chat with us
        </Link>
        , or use the following form to send us your request and we will respond with a <b> confirmation / details</b> of
        availability within <b>24 hours</b>.
      </p>
      <Pricing />
      <ReservationForm action="https://formspree.io/f/yachtchristina@gmail.com" method="POST" />
      <noscript className="gatsby-noscript">
        You need to enable javascript in order to make a reservation request via your browser. If this is not an option,
        please call us at{' '}
        <Link external to="tel:+201222118533">
          (+20) 122-211-8533
        </Link>{' '}
        or email us at{' '}
        <Link external to="mailto:yachtchristina@gmail.com">
          yachtchristina@gmail.com
        </Link>{' '}
        to proceed.
      </noscript>
    </PaddedBlock>
  </React.Fragment>
)

export default ReservationPage
